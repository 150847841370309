@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import '@fontsource/poppins';

* {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}
html, body {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
    font-family: "Space Grotesk", "Poppins";
}


#root {
    height: 100vh;
    overflow: hidden;
}