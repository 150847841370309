
/* --------------------------------- TOP PART --------------------------------- */

.myTopSwiper {
  padding-bottom: 10px;
}

.myTopSwiper .swiper-slide {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}

.myTopSwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 2px solid #6d3cfe;
  border-radius: 10px;
}

.myTopSwiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* --------------------------------- BOTTOM PART --------------------------------- */

.myBottomSwiper {
  padding-bottom: 10px;
  width: 100%;
}

/*  --------------------------------- BUTTONS --------------------------------- */

.swiper-button-prev, .swiper-button-next {
  background: #e7eaee;
  border-radius: 100px;
  width: 45px;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 16px;
  font-weight: 600;
  color: black
}